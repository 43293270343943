interface BillingData 
{
    company: string,
    bank: string,
    account: string,
    sortCode: string,
    accountNo: string
}

const billingInfo: BillingData =  {
    company: 'MS Learning Circle',
    bank: 'HSBC PLC',
    account: 'MS LEARNING CIRCLE LTD',
    sortCode: '40-07-18',
    accountNo: '71304682'  
  }

const newBillingInfo: BillingData =  {
    company: 'Redbridge Learning Centre',
    bank: 'ZEMPLAR BANK',
    account: 'Redbridge Learning Centre',
    sortCode: '08-71-99',
    accountNo: '12726319'  
    }



  export {billingInfo, newBillingInfo}